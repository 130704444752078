<template>
  <div class="app flex-row align-items-center guest">
    <div class="container pt-4 pb-4">
      <b-row class="justify-content-center">
        <b-col md="8" lg="10">
          <b-card no-body class="">
            <b-card-body class="p-4">
              <form @submit.prevent="validateBeforeSubmit">
                <router-link :to="{ name: 'Login' }">
                  <img class="mx-auto d-block mb-4 navbar-brand-full" src="img/brand/invoice-logo-2019.png" height="95"
                    alt="RTNEST Inc. Logo" />
                </router-link>
                <h1 class="h2 font-weight-bold">Register Vendor</h1>
                <p class="mt-1">
                  Put NA if you don't have this information, you can edit that
                  later.
                </p>
                <h4 class="text-muted mt-3 mb-0 pb-0">Name</h4>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-1">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname"
                        class="form-control" placeholder="First Name" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span> -->
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-1">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname"
                        class="form-control" placeholder="Last Name" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span> -->
                  </b-col>
                </b-row>

                <hr />
                <h4 class="text-muted mt-1 mb-0 pb-0">Business Information</h4>

                <b-row>
                  <b-col md="12">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" :disabled="isComapnyNameLocked" v-model="company_name"
                        @input="changeCompany_name" v-validate="'required'" name="company_name" class="form-control"
                        placeholder="Legal Company Name" />
                      <b-input-group-append v-if="isComapnyNameLocked">
                        <b-input-group-text @click="resetComanyName"><i class="fa fa-trash"></i></b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>

                    <!-- <i v-show="errors.has('company_name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('company_name')" class="help is-danger">The company name is required.</span> -->
                    <i v-show="error == true && company_name == ''" class="fa fa-exclamation-triangle"></i><span
                      class="help is-danger" v-show="error == true && company_name == ''">The company name is
                      required.</span>
                    <i v-if="suggested_company.length > 0 && !lock_company_name"><b>Did you mean
                        {{
                            suggested_company.length > 1
                              ? `(${suggested_company.length} Matches)`
                              : ''
                        }}
                        :</b>
                      <u style="cursor: pointer" class="mr-2 ml-2" @click="setComanyName(val)"
                        v-for="val in suggested_company">{{ val.value_1 }}
                      </u>
                      ?</i>
                    <i v-show="
                      company_error == true && suggested_company.length < 1
                    " class="fa fa-exclamation-triangle"></i>
                    <span v-show="
                      company_error == true && suggested_company.length < 1
                    ">
                      Your company is not a KEC approved vendor please contact
                      <a href="http://accountspayable@kiwetinohk.com/" target="_blank">
                        accountspayable@kiwetinohk.com
                      </a>
                      and provide a blank invoice with following information:<br>
                      a. Company legal name.<br>
                      b. Address.<br>
                      c. GST number<br>
                      d. Contact information of person/department in charge of billing.
                    </span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="addressline1" v-validate="'required'" name="addressline1"
                        class="form-control" placeholder="Address Line 1" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('addressline1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addressline1')" class="help is-danger">The address is required. Must contain numbers and letters.</span> -->
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="addressline2" name="addressline2" class="form-control"
                        placeholder="Address Line 2" />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="city" v-validate="'required'" name="city" class="form-control"
                        placeholder="City" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')" class="help is-danger">The city is required.</span> -->
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select type="select" :options="provinceOptions" v-model="province" v-validate="'required'"
                        name="province" class="form-control" placeholder="Province" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('province')" class="help is-danger">The province is required. Max 6 characters.</span> -->
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="postalcode" v-validate="'required|max:6'" name="postalcode"
                        class="form-control" placeholder="Postal Code" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span> -->
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="rcn" name="rcn" class="form-control"
                        placeholder="PST Number" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('rcn')" class="help is-danger">The company number is required.</span> -->
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-input-group class="mt-3 mb-2">
                      <multiselect v-model="vidcode" v-if="!toggle_vid" :loading="loading"
                        placeholder="Search For Vendor Id Code" tag-placeholder="Search For Vendor Id code"
                        :options="vidCode_data" :multiple="false" :close-on-select="true" :clear-on-select="true"
                        :preserve-search="true" :taggable="true" style="" :preselect-first="false">
                      </multiselect>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" disabled v-model="vidcode" v-if="toggle_vid" name="vidcode"
                        class="form-control" placeholder="Vendor ID Code" />
                      <b-input-group-append>
                        <b-button class="pull-right btn-sm" variant="secondary" v-if="!toggle_vid"
                          @click="toggle_vid = !toggle_vid"><i class="fa fa-chevron-down"></i></b-button>
                      </b-input-group-append>
                      <b-button class="pull-right btn-sm mt-2" variant="secondary" v-if="!toggle_vid"
                        @click="toggle_vid = !toggle_vid">Write vendor ID code</b-button>
                    </b-input-group>
                    <i v-show="error == true && vidcode == ''" class="fa fa-exclamation-triangle"></i><span
                      class="help is-danger" v-show="error == true && vidcode == ''">Vendor Id Code is required.</span>
                    <i v-show="errors.has('vidcode')" class="fa fa-exclamation-triangle"></i><span
                      v-show="errors.has('vidcode')" class="help is-danger">The vendor ID code is required.</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-input-group class="mt-3 mb-2">
                      <multiselect v-model="gst" v-if="!toggle_gst" :loading="loading"
                        placeholder="Search For GST Number" tag-placeholder="Search For GST Number" :options="gst_data"
                        :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true"
                        :taggable="true" class="mt-3 mb-2" :preselect-first="false">
                      </multiselect>
                      <b-button class="pull-right btn-sm mt-2" variant="secondary" v-if="!toggle_gst"
                        @click="toggle_gst = !toggle_gst">Write Gst Number</b-button>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input v-if="toggle_gst" disabled type="text" v-model="gst" name="gst" class="form-control"
                        placeholder="Vendor GST Code" />
                      <b-input-group-append>
                        <b-button class="pull-right btn-sm" variant="secondary" v-if="!toggle_gst"
                          @click="toggle_gst = !toggle_gst"><i class="fa fa-chevron-down"></i></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle"></i><span
                      v-show="errors.has('gst')" class="help is-danger">The vendor GST number is required.</span>
                    <i v-show="error == true && gst == ''" class="fa fa-exclamation-triangle"></i><span
                      class="help is-danger" v-show="error == true && gst == ''">GST Number is required.</span>
                  </b-col>
                </b-row>
                <h4 class="text-muted mt-1 mb-0 pb-0" v-show="false">
                  Banking Information
                </h4>
                <b-row v-show="false">
                  <b-col md="4">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="bankbranch" name="bankbranch" class="form-control"
                        placeholder="Branch Number" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('bankbranch')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('bankbranch')" class="help is-danger">The branch number is required.</span> -->
                  </b-col>
                  <b-col md="4">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="bankins" name="bankins" class="form-control"
                        placeholder="Institution Number" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('bankins')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('bankins')" class="help is-danger">The bank institution number is required.</span> -->
                  </b-col>
                  <b-col md="4">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="banknumber" name="banknumber" class="form-control"
                        placeholder="Account Number" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('banknumber')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('banknumber')" class="help is-danger">The bank account number is required.</span> -->
                  </b-col>
                </b-row>
                <hr />
                <h4 class="text-muted mt-3 mb-3 pb-0">Contact Information</h4>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="phone_num" v-validate="'required|digits:10'" name="phone_num"
                        class="form-control" placeholder="Phone Number" />
                    </b-input-group>
                    <!-- <i v-show="errors.has('phone_num')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone_num')" class="help is-danger">The phone number is required. Must be 10 digits.</span> -->
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text>@</b-input-group-text>
                      </b-input-group-prepend>
                      <input type="email" class="form-control" v-model="email" v-validate="'required|email'"
                        placeholder="Email" name="email" autocomplete="email" />
                    </b-input-group>
                    <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i>
                    <span v-show="errors.has('email')" class="help is-danger">{{
                        errors.first('email')
                    }}</span>
                    <i v-show="error == true && email == ''" class="fa fa-exclamation-triangle"></i><span
                      class="help is-danger" v-show="error == true && email == ''">Email is required.</span>
                  </b-col>
                </b-row>
                <hr />
                <h4 class="text-muted mt-3 mb-3 pb-0">Password</h4>

                <b-row class="mb-3">
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <input type="password" class="form-control" v-model="password"
                        v-validate="{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                        placeholder="Password" name="password" ref="password" />
                    </b-input-group>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <input type="password" class="form-control" v-model="password2"
                        v-validate="'required|confirmed:password'" placeholder="Password Confirmation"
                        data-vv-as="password" name="password2" />
                    </b-input-group>
                  </b-col>
                </b-row>
                <!--
                  <input type="hidden" name="robot" v-model="robot" v-validate="'required'" />
                  -->
                <span v-show="errors.has('password')">
                  <h6 class="mt-2"><i class="fa fa-exclamation-triangle"></i> Your password must: </h6>
                  <ol style="list-style-type: upper-greek;">
                    <li>Include an UPPER and lowercase letter.</li>
                    <li>Include a number.</li>
                    <li>Include one or more of these special characters: .@$!%*#?&gt&lt&quot)(^-_ </li>
                    <li>At least 14 characters</li>
                  </ol>
                </span>
                <i v-show="errors.has('password2')" class="mb-2 fa fa-exclamation-triangle"></i> <span
                  v-show="errors.has('password2')" class="help is-danger">{{ errors.first('password2') }}</span>
                <b-input-group>
                  <vue-recaptcha ref="reCaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" class="mt-2"
                    sitekey="6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6">
                  </vue-recaptcha>
                </b-input-group>
                <p class="mt-3">
                  <i v-show="errors.has('robot')" class="fa fa-exclamation-triangle"></i>
                  <span v-show="errors.has('robot')" class="mb-3 help is-danger">The reCaptcha is required.</span>
                </p>
                <p>
                  <i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error"
                    class="help is-danger">Signup failed. Please contact with email:
                    <a href="javascript:void(0)">support@rtnest.ca</a></span>
                </p>
                <b-button variant="primary" class="mt-4" type="submit" block>Create Account</b-button>
              </form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import Multiselect from 'vue-multiselect';
export default {
  name: 'RegisterVendor',
  components: {
    VueRecaptcha,
    Multiselect,
  },
  data: function () {
    return {
      robot: '',
      vidcode: '',
      email: '',
      fname: '',
      lname: '',
      company_name: '',
      rcn: '',
      phone_num: null,
      fax_num: null,
      gst: '',
      postalcode: '',
      bankaccount: '',
      bankins: '',
      banknumber: '',
      bankbranch: '',
      password: '',
      password2: '',
      error: '',
      province: null,
      city: '',
      addressline1: '',
      addressline2: '',
      company_data: [],
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: 'Alberta', text: 'Alberta' },
        { value: 'British Columbia', text: 'British Columbia' },
        { value: 'Manitoba', text: 'Manitoba' },
        { value: 'New Brunswick', text: 'New Brunswick' },
        {
          value: 'Newfoundland and Labrador',
          text: 'Newfoundland and Labrador',
        },
        { value: 'Nova Scotia', text: 'Nova Scotia' },
        { value: 'Ontario', text: 'Ontario' },
        { value: 'Prince Edward Island', text: 'Prince Edward Island' },
        { value: 'Quebec', text: 'Quebec' },
        { value: 'Saskatchewan', text: 'Saskatchewan' },
        { value: 'Northwest Territories', text: 'Northwest Territories' },
        { value: 'Nunavut', text: 'Nunavut' },
        { value: 'Yukon', text: 'Yukon' },
      ],
      company_data: [],
      loading: true,
      gst_data: [],
      vidCode_data: [],
      toggle_gst: true,
      toggle_vid: true,
      companies: [],
      company_error: false,
      suggested_company: [],
      lock_company_name: false,
    };
  },
  computed: {
    isComapnyNameLocked() {
      // evaluate whatever you need to determine disabled here...
      return this.lock_company_name == true;
    },
  },
  methods: {
    onCaptchaExpired: function () {
      this.robot = '';
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.robot = 'true';
    },
    changeCompany_name() {
      this.selected_company = '';
      this.company_name = this.company_name.toUpperCase();
      const regex = new RegExp(`^${this.company_name.replace('/\W/', '')}`);
      let suggested_company = this.company_data.filter(
        (x) => regex.test(x.value_1) && this.company_name.length > 4
      );
      this.suggested_company =
        suggested_company.length < 4 ? suggested_company : [];
      if (
        this.suggested_company.length > 0 &&
        this.company_name === this.suggested_company.value_1
      ) {
        this.lock_company_name = true;
        this.vidcode = this.suggested_company.value_2;
        this.gst =
          this.suggested_company.value_3 == null
            ? ''
            : this.suggested_company.value_3;
        this.company_error = false;
      } else {

        this.lock_company_name = false;
        this.gst = '';
        this.vidcode = '';
        this.company_error = true;
        // alert('There is an error')
        // this.$toasted.show('There is an error')
      }
    },
    validateBeforeSubmit() {
      if (
        this.email != '' &&
        this.gst != '' &&
        this.company_name != '' &&
        this.password != '' &&
        this.password2 == this.password
      ) {
        this.bankaccount =
          this.bankins + '-' + this.bankbranch + '-' + this.banknumber;
        let data = JSON.stringify({
          vidcode: this.vidcode,
          email: this.email,
          fname: this.fname,
          lname: this.lname,
          company_name: this.company_name,
          rcn: this.rcn,
          password: this.password,
          phone_num: this.phone_num,
          fax_num: this.fax_num,
          gst: this.gst,
          province: this.province,
          city: this.city,
          addressline1: this.addressline1,
          addressline2: this.addressline2,
          postalcode: this.postalcode,
          bankaccount: this.bankaccount,
        });
        this.$axios
          .post('/vendor/signup', data)
          .then((response) => {
            if (response.status == 201) {
              this.error = true;
              this.$toasted.show(
                'Please use the email that received the invite from RTNest.',
                { type: 'error', duration: '3000' }
              );
            }
            if (response.status === 200) {
              this.flash({
                message: 'Registration Successful',
                variant: 'success',
              });
              this.$router.push('/');
            }
          })
          .catch((error) => {
            this.$refs.reCaptcha.reset();
            this.error = true;
            this.robot = '';
          });
        return;
      } else {
        this.$refs.reCaptcha.reset();
        this.error = true;
        this.robot = '';
      }
    },
    setComanyName: function (val) {
      this.company_name = val.value_1;
      this.vidcode = val.value_2;
      this.gst = val.value_3 == null ? '' : val.value_3;
      this.lock_company_name = true;
      this.company_error = false;
    },
    resetComanyName: function () {
      this.company_name = '';
      this.vidcode = '';
      this.gst = '';
      this.lock_company_name = false;
      this.suggested_company = [];
      this.company_error = false;
    },
  },
  mounted() {
    // business asscoiate id = 9;
    this.$http
      .get('/wts/get/business_asscociates/' + 9 + '/' + this.$route.query.id)
      .then((response) => {
        this.company_data = response.data.values;
        // this.gst_data = response.data.values.filter(x=> x.value_3 !== null).map(y=> y.value_3);
        // this.vidCode_data = response.data.values.filter(x=> x.value_2 !== null).map(y=> y.value_2);
        this.loading = false;
      });
  },
};
</script>
