var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app flex-row align-items-center guest" }, [
    _c(
      "div",
      { staticClass: "container pt-4 pb-4" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "8", lg: "10" } },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-card-body", { staticClass: "p-4" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.validateBeforeSubmit($event)
                            }
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "Login" } } },
                            [
                              _c("img", {
                                staticClass:
                                  "mx-auto d-block mb-4 navbar-brand-full",
                                attrs: {
                                  src: "img/brand/invoice-logo-2019.png",
                                  height: "95",
                                  alt: "RTNEST Inc. Logo"
                                }
                              })
                            ]
                          ),
                          _c("h1", { staticClass: "h2 font-weight-bold" }, [
                            _vm._v("Register Vendor")
                          ]),
                          _c("p", { staticClass: "mt-1" }, [
                            _vm._v(
                              "\n                Put NA if you don't have this information, you can edit that\n                later.\n              "
                            )
                          ]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-3 mb-0 pb-0" },
                            [_vm._v("Name")]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-1" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "icon-user"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "fname",
                                          placeholder: "First Name"
                                        },
                                        model: {
                                          value: _vm.fname,
                                          callback: function($$v) {
                                            _vm.fname = $$v
                                          },
                                          expression: "fname"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-1" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "icon-user"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "lname",
                                          placeholder: "Last Name"
                                        },
                                        model: {
                                          value: _vm.lname,
                                          callback: function($$v) {
                                            _vm.lname = $$v
                                          },
                                          expression: "lname"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-1 mb-0 pb-0" },
                            [_vm._v("Business Information")]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.isComapnyNameLocked,
                                          name: "company_name",
                                          placeholder: "Legal Company Name"
                                        },
                                        on: { input: _vm.changeCompany_name },
                                        model: {
                                          value: _vm.company_name,
                                          callback: function($$v) {
                                            _vm.company_name = $$v
                                          },
                                          expression: "company_name"
                                        }
                                      }),
                                      _vm.isComapnyNameLocked
                                        ? _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-input-group-text",
                                                {
                                                  on: {
                                                    click: _vm.resetComanyName
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-trash"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.error == true &&
                                          _vm.company_name == "",
                                        expression:
                                          "error == true && company_name == ''"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.error == true &&
                                            _vm.company_name == "",
                                          expression:
                                            "error == true && company_name == ''"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The company name is\n                    required."
                                      )
                                    ]
                                  ),
                                  _vm.suggested_company.length > 0 &&
                                  !_vm.lock_company_name
                                    ? _c(
                                        "i",
                                        [
                                          _c("b", [
                                            _vm._v(
                                              "Did you mean\n                      " +
                                                _vm._s(
                                                  _vm.suggested_company.length >
                                                    1
                                                    ? "(" +
                                                        _vm.suggested_company
                                                          .length +
                                                        " Matches)"
                                                    : ""
                                                ) +
                                                "\n                      :"
                                            )
                                          ]),
                                          _vm._l(
                                            _vm.suggested_company,
                                            function(val) {
                                              return _c(
                                                "u",
                                                {
                                                  staticClass: "mr-2 ml-2",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setComanyName(
                                                        val
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(val.value_1) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v("\n                    ?")
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.company_error == true &&
                                          _vm.suggested_company.length < 1,
                                        expression:
                                          "\n                    company_error == true && suggested_company.length < 1\n                  "
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.company_error == true &&
                                            _vm.suggested_company.length < 1,
                                          expression:
                                            "\n                    company_error == true && suggested_company.length < 1\n                  "
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Your company is not a KEC approved vendor please contact\n                    "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "http://accountspayable@kiwetinohk.com/",
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      accountspayable@kiwetinohk.com\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    and provide a blank invoice with following information:"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                    a. Company legal name."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                    b. Address."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                    c. GST number"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                    d. Contact information of person/department in charge of billing.\n                  "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "addressline1",
                                          placeholder: "Address Line 1"
                                        },
                                        model: {
                                          value: _vm.addressline1,
                                          callback: function($$v) {
                                            _vm.addressline1 = $$v
                                          },
                                          expression: "addressline1"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "addressline2",
                                          placeholder: "Address Line 2"
                                        },
                                        model: {
                                          value: _vm.addressline2,
                                          callback: function($$v) {
                                            _vm.addressline2 = $$v
                                          },
                                          expression: "addressline2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "city",
                                          placeholder: "City"
                                        },
                                        model: {
                                          value: _vm.city,
                                          callback: function($$v) {
                                            _vm.city = $$v
                                          },
                                          expression: "city"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "select",
                                          options: _vm.provinceOptions,
                                          name: "province",
                                          placeholder: "Province"
                                        },
                                        model: {
                                          value: _vm.province,
                                          callback: function($$v) {
                                            _vm.province = $$v
                                          },
                                          expression: "province"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|max:6",
                                            expression: "'required|max:6'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "postalcode",
                                          placeholder: "Postal Code"
                                        },
                                        model: {
                                          value: _vm.postalcode,
                                          callback: function($$v) {
                                            _vm.postalcode = $$v
                                          },
                                          expression: "postalcode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "rcn",
                                          placeholder: "PST Number"
                                        },
                                        model: {
                                          value: _vm.rcn,
                                          callback: function($$v) {
                                            _vm.rcn = $$v
                                          },
                                          expression: "rcn"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      !_vm.toggle_vid
                                        ? _c("multiselect", {
                                            attrs: {
                                              loading: _vm.loading,
                                              placeholder:
                                                "Search For Vendor Id Code",
                                              "tag-placeholder":
                                                "Search For Vendor Id code",
                                              options: _vm.vidCode_data,
                                              multiple: false,
                                              "close-on-select": true,
                                              "clear-on-select": true,
                                              "preserve-search": true,
                                              taggable: true,
                                              "preselect-first": false
                                            },
                                            model: {
                                              value: _vm.vidcode,
                                              callback: function($$v) {
                                                _vm.vidcode = $$v
                                              },
                                              expression: "vidcode"
                                            }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm.toggle_vid
                                        ? _c("b-form-input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                              name: "vidcode",
                                              placeholder: "Vendor ID Code"
                                            },
                                            model: {
                                              value: _vm.vidcode,
                                              callback: function($$v) {
                                                _vm.vidcode = $$v
                                              },
                                              expression: "vidcode"
                                            }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          !_vm.toggle_vid
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "pull-right btn-sm",
                                                  attrs: {
                                                    variant: "secondary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.toggle_vid = !_vm.toggle_vid
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-chevron-down"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      !_vm.toggle_vid
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "pull-right btn-sm mt-2",
                                              attrs: { variant: "secondary" },
                                              on: {
                                                click: function($event) {
                                                  _vm.toggle_vid = !_vm.toggle_vid
                                                }
                                              }
                                            },
                                            [_vm._v("Write vendor ID code")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.error == true &&
                                          _vm.vidcode == "",
                                        expression:
                                          "error == true && vidcode == ''"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.error == true &&
                                            _vm.vidcode == "",
                                          expression:
                                            "error == true && vidcode == ''"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("Vendor Id Code is required.")]
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("vidcode"),
                                        expression: "errors.has('vidcode')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("vidcode"),
                                          expression: "errors.has('vidcode')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The vendor ID code is required.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      !_vm.toggle_gst
                                        ? _c("multiselect", {
                                            staticClass: "mt-3 mb-2",
                                            attrs: {
                                              loading: _vm.loading,
                                              placeholder:
                                                "Search For GST Number",
                                              "tag-placeholder":
                                                "Search For GST Number",
                                              options: _vm.gst_data,
                                              multiple: false,
                                              "close-on-select": true,
                                              "clear-on-select": true,
                                              "preserve-search": true,
                                              taggable: true,
                                              "preselect-first": false
                                            },
                                            model: {
                                              value: _vm.gst,
                                              callback: function($$v) {
                                                _vm.gst = $$v
                                              },
                                              expression: "gst"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.toggle_gst
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "pull-right btn-sm mt-2",
                                              attrs: { variant: "secondary" },
                                              on: {
                                                click: function($event) {
                                                  _vm.toggle_gst = !_vm.toggle_gst
                                                }
                                              }
                                            },
                                            [_vm._v("Write Gst Number")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm.toggle_gst
                                        ? _c("b-form-input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                              name: "gst",
                                              placeholder: "Vendor GST Code"
                                            },
                                            model: {
                                              value: _vm.gst,
                                              callback: function($$v) {
                                                _vm.gst = $$v
                                              },
                                              expression: "gst"
                                            }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          !_vm.toggle_gst
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "pull-right btn-sm",
                                                  attrs: {
                                                    variant: "secondary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.toggle_gst = !_vm.toggle_gst
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-chevron-down"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("gst"),
                                        expression: "errors.has('gst')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("gst"),
                                          expression: "errors.has('gst')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The vendor GST number is required."
                                      )
                                    ]
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.error == true && _vm.gst == "",
                                        expression: "error == true && gst == ''"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.error == true && _vm.gst == "",
                                          expression:
                                            "error == true && gst == ''"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("GST Number is required.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "h4",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              staticClass: "text-muted mt-1 mb-0 pb-0"
                            },
                            [
                              _vm._v(
                                "\n                Banking Information\n              "
                              )
                            ]
                          ),
                          _c(
                            "b-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ]
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "bankbranch",
                                          placeholder: "Branch Number"
                                        },
                                        model: {
                                          value: _vm.bankbranch,
                                          callback: function($$v) {
                                            _vm.bankbranch = $$v
                                          },
                                          expression: "bankbranch"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "bankins",
                                          placeholder: "Institution Number"
                                        },
                                        model: {
                                          value: _vm.bankins,
                                          callback: function($$v) {
                                            _vm.bankins = $$v
                                          },
                                          expression: "bankins"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "banknumber",
                                          placeholder: "Account Number"
                                        },
                                        model: {
                                          value: _vm.banknumber,
                                          callback: function($$v) {
                                            _vm.banknumber = $$v
                                          },
                                          expression: "banknumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-3 mb-3 pb-0" },
                            [_vm._v("Contact Information")]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|digits:10",
                                            expression: "'required|digits:10'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "phone_num",
                                          placeholder: "Phone Number"
                                        },
                                        model: {
                                          value: _vm.phone_num,
                                          callback: function($$v) {
                                            _vm.phone_num = $$v
                                          },
                                          expression: "phone_num"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _vm._v("@")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.email,
                                            expression: "email"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|email",
                                            expression: "'required|email'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "email",
                                          placeholder: "Email",
                                          name: "email",
                                          autocomplete: "email"
                                        },
                                        domProps: { value: _vm.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.email = $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("email"),
                                        expression: "errors.has('email')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("email"),
                                          expression: "errors.has('email')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.error == true && _vm.email == "",
                                        expression:
                                          "error == true && email == ''"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.error == true &&
                                            _vm.email == "",
                                          expression:
                                            "error == true && email == ''"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("Email is required.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-3 mb-3 pb-0" },
                            [_vm._v("Password")]
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-lock"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password,
                                            expression: "password"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required: true,
                                              min: 14,
                                              regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
                                            },
                                            expression:
                                              "{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                                          }
                                        ],
                                        ref: "password",
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "password",
                                          placeholder: "Password",
                                          name: "password"
                                        },
                                        domProps: { value: _vm.password },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password = $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-lock"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password2,
                                            expression: "password2"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "required|confirmed:password",
                                            expression:
                                              "'required|confirmed:password'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "password",
                                          placeholder: "Password Confirmation",
                                          "data-vv-as": "password",
                                          name: "password2"
                                        },
                                        domProps: { value: _vm.password2 },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password2 = $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("password"),
                                  expression: "errors.has('password')"
                                }
                              ]
                            },
                            [
                              _c("h6", { staticClass: "mt-2" }, [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-triangle"
                                }),
                                _vm._v(" Your password must: ")
                              ]),
                              _c(
                                "ol",
                                {
                                  staticStyle: {
                                    "list-style-type": "upper-greek"
                                  }
                                },
                                [
                                  _c("li", [
                                    _vm._v(
                                      "Include an UPPER and lowercase letter."
                                    )
                                  ]),
                                  _c("li", [_vm._v("Include a number.")]),
                                  _c("li", [
                                    _vm._v(
                                      'Include one or more of these special characters: .@$!%*#?><")(^-_ '
                                    )
                                  ]),
                                  _c("li", [_vm._v("At least 14 characters")])
                                ]
                              )
                            ]
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("password2"),
                                expression: "errors.has('password2')"
                              }
                            ],
                            staticClass: "mb-2 fa fa-exclamation-triangle"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("password2"),
                                  expression: "errors.has('password2')"
                                }
                              ],
                              staticClass: "help is-danger"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("password2")))]
                          ),
                          _c(
                            "b-input-group",
                            [
                              _c("vue-recaptcha", {
                                ref: "reCaptcha",
                                staticClass: "mt-2",
                                attrs: {
                                  sitekey:
                                    "6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6"
                                },
                                on: {
                                  verify: _vm.onCaptchaVerified,
                                  expired: _vm.onCaptchaExpired
                                }
                              })
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-3" }, [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("robot"),
                                  expression: "errors.has('robot')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("robot"),
                                    expression: "errors.has('robot')"
                                  }
                                ],
                                staticClass: "mb-3 help is-danger"
                              },
                              [_vm._v("The reCaptcha is required.")]
                            )
                          ]),
                          _c("p", [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.error,
                                  expression: "error"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.error,
                                    expression: "error"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [
                                _vm._v(
                                  "Signup failed. Please contact with email:\n                  "
                                ),
                                _c(
                                  "a",
                                  { attrs: { href: "javascript:void(0)" } },
                                  [_vm._v("support@rtnest.ca")]
                                )
                              ]
                            )
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                variant: "primary",
                                type: "submit",
                                block: ""
                              }
                            },
                            [_vm._v("Create Account")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }